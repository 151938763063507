
  export default {
    name: "Link",
    props: {
      classes: {
        type: String,
        default: ''
      },
      url: {
        type: String,
        default: ''
      },
      target: {
        type: String,
        default: '_self'
      },
      obfuscated: {
        type: Boolean,
        default: false
      },
      internal: {
        type: Boolean,
        default: false
      }
    },
    methods : {
      openLink(url, target, internal) {
        if (target == '_blank') {
          var newWindow = window.open(url, '_blank');
          newWindow.focus();
        } else if (!internal) {
          document.location.href = url;
        }
        else {
          this.$router.push(url);
        }
  }
    }
  }
